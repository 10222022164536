<ng-container
  [ngTemplateOutlet]="template"
  [ngTemplateOutletContext]="{
    showCheckbox: showCheckbox$ | async
  }"
>
</ng-container>

<ng-template #template let-showCheckbox="showCheckbox">
  @if (showCheckbox) {
    <mat-checkbox [formControl]="formControl">{{ props.label }}</mat-checkbox>
  }
</ng-template>
