import { HttpClient, HttpParams } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { ContentData, PageResult } from "@vp/models";
import { API_BASE_URL } from "@vp/shared/tokens";
import { Operation } from "rfc6902";
import { Observable } from "rxjs";
import { ContentDataFilter } from "../models/content-data-filter";

export interface ContentTypeUpdateResponse {
  contentTypeChanged: boolean;
}

@Injectable({
  providedIn: "root"
})
export class ContentApiService {
  constructor(
    @Inject(API_BASE_URL) private apiBaseUrl: string,
    private readonly _http: HttpClient
  ) {}

  filteredContents = (filter: Partial<ContentDataFilter>): Observable<PageResult<ContentData>> => {
    if (filter.take === undefined || filter.take === null) {
      filter.take = 10;
    }
    if (filter.skip === undefined || filter.skip === null) {
      filter.skip = 0;
    }
    let apiURL = `${this.apiBaseUrl}/contentsearch/?take=${filter.take}&skip=${filter.skip}`;

    if (filter.contentTypeId && filter.contentTypeId != "all") {
      apiURL = apiURL + `&filter=contentTypeId=${filter.contentTypeId}`;
    }

    if (filter.search) {
      apiURL = apiURL + `&search=${filter.search}`;
    }
    if (filter.sort) {
      apiURL = apiURL + `&sort=${filter.sort}`;
    }
    if (filter.sortDir) {
      apiURL = apiURL + `&sortDir=${filter.sortDir}`;
    }
    return this._http.get<PageResult<ContentData>>(apiURL);
  };

  public createContent = (content: ContentData): Observable<ContentData> => {
    const apiURL = `${this.apiBaseUrl}/content`;
    return this._http.post<ContentData>(apiURL, content);
  };

  public patch = (contentId: string | undefined, operations: Operation[] | undefined) => {
    const apiURL = `${this.apiBaseUrl}/content/${contentId}`;
    return this._http.patch(apiURL, operations);
  };

  getContent(contentId: string, contentTypeId: string | null = null): Observable<ContentData> {
    const apiURL = `${this.apiBaseUrl}/content/${contentId}`;
    let params = new HttpParams();
    if (contentTypeId) {
      params = params.append("contentTypeId", String(contentTypeId));
    }
    return this._http.get<ContentData>(apiURL, {
      params: params
    });
  }

  downloadContent(contentId: string): Observable<Blob> {
    const apiURL = `${this.apiBaseUrl}/content/${contentId}?downloadContent=true`;
    return this._http.get<Blob>(apiURL, { responseType: "blob" as "json" });
  }

  getAllContents(): Observable<ContentData[]> {
    const apiURL = `${this.apiBaseUrl}/content`;
    return this._http.get<ContentData[]>(apiURL, {});
  }

  getContentsByCaseId(caseId: string): Observable<ContentData[]> {
    const apiURL = `${this.apiBaseUrl}/contents/${caseId}`;
    return this._http.get<ContentData[]>(apiURL, {});
  }
}
