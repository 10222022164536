import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit
} from "@angular/core";
import { MomentDateAdapter } from "@angular/material-moment-adapter";
import { DateAdapter, MAT_DATE_LOCALE } from "@angular/material/core";
import { MomentDatetimeAdapter } from "@ng-matero/extensions-moment-adapter";
import { DatetimeAdapter } from "@ng-matero/extensions/core";
import {
  MtxCalendarView,
  MtxDatetimepickerMode,
  MtxDatetimepickerType
} from "@ng-matero/extensions/datetimepicker";
import { FieldType, FieldTypeConfig } from "@ngx-formly/core";
import moment from "moment";
import { BehaviorSubject, Subject } from "rxjs";

@Component({
  selector: "lib-time-picker-type",
  templateUrl: "./time-picker-type.component.html",
  styleUrls: ["./time-picker-type.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: DatetimeAdapter, useClass: MomentDatetimeAdapter }
  ]
})
export class TimePickerTypeComponent
  extends FieldType<FieldTypeConfig>
  implements OnInit, AfterViewInit, OnDestroy
{
  private readonly _destroyed$ = new Subject<void>();
  disabled$ = new BehaviorSubject<boolean>(false);

  type: MtxDatetimepickerType = "time";
  mode: MtxDatetimepickerMode = "auto";
  startView: MtxCalendarView = "clock";
  multiYearSelector = false;
  touchUi = false;
  twelvehour = false;
  timeInterval = 1;
  timeInput = true;

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.disabled$.next(this.formControl.disabled);
    if (this.props.twelveHour) {
      this.twelvehour = this.props.twelveHour;
    }

    if (this.formControl.value) {
      this.setTime(this.formControl.value);
    }
  }

  ngAfterViewInit(): void {
    this.formControl.valueChanges.subscribe(value => {
      this.disabled$.next(this.formControl.disabled);
      if (typeof value === "string") {
        this.setTime(value);
      } else if (
        typeof this.formControl.value === "object" &&
        (this.formControl.value instanceof moment || this.formControl.value instanceof Date)
      ) {
        const formattedDate = moment(this.formControl.value).format();
        this.formControl.setValue(formattedDate, { emitEvent: false });
      }
    });
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  setTime(time: string) {
    const fullDateTime = `${moment().format("YYYY-MM-DD")}T${time}`;
    const reconstructedMoment = moment(fullDateTime, "YYYY-MM-DDTHH:mm:ssZ");
    if (reconstructedMoment.isValid()) {
      this.formControl.setValue(reconstructedMoment, { emitEvent: false });
    }
  }
}
