import { ChangeDetectionStrategy, Component, OnInit, ViewChild } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { MomentDateAdapter } from "@angular/material-moment-adapter";
import { DateAdapter, MAT_DATE_LOCALE } from "@angular/material/core";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import { MomentDatetimeAdapter } from "@ng-matero/extensions-moment-adapter";
import { DatetimeAdapter } from "@ng-matero/extensions/core";
import {
  MtxCalendarView,
  MtxDatetimepickerMode,
  MtxDatetimepickerType
} from "@ng-matero/extensions/datetimepicker";
import { FieldType, FieldTypeConfig } from "@ngx-formly/core";
import { Select } from "@ngxs/store";
import { CaseState } from "@vp/data-access/case";
import { CaseData } from "@vp/models";
import { filterNullMap } from "@vp/shared/operators";
import { getValueAtPath } from "@vp/shared/utilities";
import { map, Observable, tap } from "rxjs";
import { DateDirection } from "../formly-dob-picker/formly-dob-picker.component";

@Component({
  selector: "lib-formly-hidden-type",
  templateUrl: "./formly-hidden-type.component.html",
  styleUrl: "./formly-hidden-type.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: DatetimeAdapter, useClass: MomentDatetimeAdapter }
  ]
})
export class FormlyHiddenTypeComponent extends FieldType<FieldTypeConfig> implements OnInit {
  @Select(CaseState.current) public caseData$!: Observable<CaseData>;

  maxDate!: Date | null;
  minDate!: Date | null;
  selOptions: string[] = [];
  type: MtxDatetimepickerType = "datetime";
  mode: MtxDatetimepickerMode = "auto";
  startView: MtxCalendarView = "month";
  multiYearSelector = false;
  touchUi = false;
  twelvehour = false;
  timeInterval = 1;
  timeInput = true;

  @ViewChild("picker") picker: any;

  ngOnInit(): void {
    this.caseData$
      .pipe(
        filterNullMap(),
        map((casedata: CaseData) => casedata.recordData),
        tap(recordData => {
          const fieldParent = this.field.parent?.key?.toString();
          const field = this.field.key?.toString();
          if (fieldParent && field) {
            const recordDataField = getValueAtPath(recordData, [fieldParent, field]);

            if (recordDataField) {
              this.formControl.patchValue(recordDataField);
            }
            if (this.props.type === "select") {
              this.selOptions = this.props.options as string[];
            }
            if (this.props.type === "date-time") {
              this.type = "datetime";
              this.startView = "month";
            }
            if (this.props.type === "time") {
              this.type = "time";
              this.startView = "clock";
            }
            if (this.props.dateDirection === DateDirection.Past) {
              this.maxDate = new Date();
              this.minDate = null;
            }
          }
        })
      )
      .subscribe();
  }

  validateDate(value: any) {
    if (
      value &&
      value._i?.length === value._f?.length &&
      this.maxDate &&
      new Date(value) > this.maxDate
    ) {
      this.formControl.setErrors({ maxDate: true });
    } else {
      const errors = this.formControl.errors;
      if (errors) {
        delete errors["maxDate"];
        if (Object.keys(errors).length === 0) {
          this.formControl.setErrors(null);
        } else {
          this.formControl.setErrors(errors);
        }
      }
    }
  }

  formatDate(event: MatDatepickerInputEvent<Date>): void {
    if (event.value) {
      const formattedDate = new Date(event.value).toISOString().split("T")[0];
      this.model[this.key as string] = formattedDate;
      this.formControl.setValue(formattedDate, { emitEvent: false });
      this.validateDate(formattedDate);
    }
  }

  isErrorState(control: UntypedFormControl | null): boolean {
    return !!(control?.invalid && (control?.dirty || control?.touched));
  }
}
