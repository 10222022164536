import { ChangeDetectionStrategy, Component, OnDestroy } from "@angular/core";
import { Store } from "@ngxs/store";
import { ApplicationState } from "@vp/data-access/application";
import { CaseState } from "@vp/data-access/case";
import { FeatureService } from "@vp/shared/features";
import { getDataValueFromDotPath } from "@vp/shared/utilities";
import { BehaviorSubject, map, Subject } from "rxjs";
import { SchemaFieldType } from "../directives/schema-field-type.directive";

enum TogglerAction {
  HideWhenOn = "hideWhenOn",
  ShowWhenOn = "showWhenOn",
  ClearWhenOn = "clearWhenOn",
  ClearWhenOff = "clearWhenOff",
  DisabledWhenOn = "disabledWhenOn",
  DisabledWhenOff = "disabledWhenOff",
  EnabledWhenOff = "enabledWhenOff"
}

export interface TogglerTrigger {
  togglerAction: string;
  field: string;
}

@Component({
  selector: "vp-formly-onbehalf-toggler",
  templateUrl: "./formly-onbehalf-toggler.component.html",
  styleUrl: "./formly-onbehalf-toggler.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormlyOnBehalfTogglerComponent extends SchemaFieldType implements OnDestroy {
  private readonly _destroyed$ = new Subject<void>();
  private readonly _selectedValue$ = new BehaviorSubject<boolean>(false);

  selectedValue$ = this._selectedValue$.asObservable();

  rolesAllowedToUseOnBehalfFeature$ = this.featureService.configurationLists$("caseCreate").pipe(
    map(configurationList => {
      if (configurationList) {
        const authenticatedUser = this.store.selectSnapshot(ApplicationState.loggedInUser);
        const currentRole = authenticatedUser?.roles.find(
          x => x.roleId === authenticatedUser?.selectedRoleId
        );
        const rolesAllowed = configurationList["rolesAllowedToUseOnBehalfFeature"];
        return rolesAllowed?.includes(currentRole?.friendlyId || "");
      }
      return [];
    })
  );

  constructor(
    private readonly store: Store,
    private readonly featureService: FeatureService
  ) {
    super();
  }

  ngOnInit(): void {
    const caseData = this.store.selectSnapshot(CaseState.current);
    if (caseData?.recordData) {
      const chkOnBehalf =
        (getDataValueFromDotPath(caseData?.recordData, "homeDoctor.chkOnBehalf") as boolean) ??
        false;
      if (chkOnBehalf) {
        this._selectedValue$.next(chkOnBehalf);
        this.setFieldValue("chkOnBehalf", chkOnBehalf);
      }
    }
  }

  setFieldValue(fieldName: string, value: any) {
    const fieldGroup = this.field.parent?.fieldGroup?.find(f => f.key === fieldName);
    if (fieldGroup) {
      fieldGroup.formControl?.setValue(value);
    }
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  onToggle(selectedValue: any) {
    const newValue = selectedValue.checked;
    this._selectedValue$.next(newValue);
    const listOfActions = (this.props.triggers as TogglerTrigger[]) ?? [];
    listOfActions.map(x => {
      this.triggerController(x.field, x.togglerAction, newValue);
    });
    const fieldGroup = this.field.parent?.fieldGroup?.find(f => f.key === "chkOnBehalf");
    if (fieldGroup) {
      fieldGroup.formControl?.setValue(newValue);
    }
  }

  triggerController(fieldName: string, togglerAction: string, selectedValue: boolean) {
    const fieldGroup = this.field.parent?.fieldGroup?.find(f => f.key === fieldName);
    if (fieldGroup) {
      switch (togglerAction) {
        case TogglerAction.HideWhenOn:
          fieldGroup.hide = selectedValue ? true : false;
          break;
        case TogglerAction.ShowWhenOn:
          fieldGroup.hide = selectedValue ? false : true;
          break;
        case TogglerAction.ClearWhenOn:
          if (selectedValue) fieldGroup.formControl?.setValue("");
          break;
        case TogglerAction.ClearWhenOff:
          if (!selectedValue) fieldGroup.formControl?.setValue("");
          break;
        case TogglerAction.DisabledWhenOn:
          if (selectedValue) {
            fieldGroup?.formControl?.disable();
          }
          break;
        case TogglerAction.DisabledWhenOff:
          if (!selectedValue) {
            fieldGroup?.formControl?.disable();
          }
          break;
        case TogglerAction.EnabledWhenOff:
          if (!selectedValue) {
            fieldGroup?.formControl?.enable();
          }
          break;
        default:
          break;
      }
    }
  }
}
