<mat-form-field flex>
  <mat-label>{{ props.label }}</mat-label>

  <!-- The timepicker input -->
  <mtx-datetimepicker-toggle
    [ngClass]="{ toggleDisabled: (disabled$ | async) }"
    matSuffix
    [for]="datetimePicker"
  ></mtx-datetimepicker-toggle>
  <mtx-datetimepicker
    #datetimePicker
    [type]="type"
    [mode]="mode"
    [startView]="startView"
    [twelvehour]="twelvehour"
    [timeInterval]="timeInterval"
    [timeInput]="timeInput"
    [touchUi]="touchUi"
    [multiYearSelector]="multiYearSelector"
  ></mtx-datetimepicker>
  <input
    [required]="props.required ? true : false"
    [mtxDatetimepicker]="datetimePicker"
    [formControl]="formControl"
    autocomplete="false"
    matInput
  />
</mat-form-field>
