import { Inject, Injectable, isDevMode } from "@angular/core";
import { OKTA_CONSUMER_ENV, OKTA_LOGIN_BRAND } from "@vp/shared/tokens";
import { AuthenticatedResult, OidcSecurityService, UserDataResult } from "angular-auth-oidc-client";
import { AuthOptions } from "angular-auth-oidc-client/lib/auth-options";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class AuthenticationService {
  private _isLoggingOut = false;

  constructor(
    @Inject(OKTA_LOGIN_BRAND) private oktaLoginBrand: string,
    @Inject(OKTA_CONSUMER_ENV) private consumerOktaEnv: string,
    private oidcSecurityService: OidcSecurityService
  ) {}

  get isLoggingOut(): boolean {
    return this._isLoggingOut;
  }

  checkAuth() {
    return this.oidcSecurityService.checkAuth();
  }

  isLoggedIn$(): Observable<AuthenticatedResult> {
    return this.oidcSecurityService.isAuthenticated$;
  }

  checkSessionChanged(): Observable<boolean> {
    return this.oidcSecurityService.checkSessionChanged$;
  }

  getAccount(): Observable<UserDataResult> {
    return this.oidcSecurityService.userData$;
  }

  login(register = false): void {
    const authOptions: AuthOptions = {
      customParams: {
        brand: this.oktaLoginBrand as string,
        display_env: this.consumerOktaEnv as string
      }
    };

    if (authOptions.customParams) {
      if (register) authOptions.customParams.ui_hint = "register";
      if (isDevMode()) authOptions.customParams.display_env = "stg";
    }
    this.oidcSecurityService.authorize(undefined, authOptions);
  }

  logout(): void {
    this._isLoggingOut = true;
    this.oidcSecurityService.logoff().subscribe();
    this._isLoggingOut = false;
  }

  getAccessToken(): Observable<string> {
    return this.oidcSecurityService.getAccessToken();
  }

  getIdToken(): Observable<string> {
    return this.oidcSecurityService.getIdToken();
  }

  //#endregion
}
