import { Injectable } from "@angular/core";
import { Store } from "@ngxs/store";
import { OrganizationState } from "@vp/data-access/organization";
import { PermissionsConstService } from "@vp/shared/permissions-const";
import { UiDisplayTagService } from "@vp/shared/store/ui";
import { defer, of } from "rxjs";

export interface NavigationItem {
  key: string;
  displayName: string;
  friendlyName: string;
  routerLink: string;
  extraRouterLinks?: string[];
  enabled: boolean;
  valid: boolean;
  active: boolean;
  order: number;
  permissions: string[];
  icon?: string;
  showActionIndicator?: boolean;
  showOnMobile?: boolean;
  showOnTablet?: boolean;
  showOnDesktop?: boolean;
}

@Injectable({
  providedIn: "root"
})
export class AppNavService {
  checkContentFeature = false;
  constructor(
    public _PermissionsConstService: PermissionsConstService,
    private uiDisplayTagService: UiDisplayTagService,
    private store: Store
  ) {}

  menuItems = defer(() => this.resolve());

  private resolve() {
    const navItems: NavigationItem[] = [
      {
        key: "dashboard",
        displayName: this.uiDisplayTagService.displayTags.dashboardNav,
        friendlyName: "dashboard",
        routerLink: "/dashboard/overview",
        extraRouterLinks: ["/dashboard"],
        enabled: true,
        valid: true,
        active: false,
        order: 1,
        permissions: [this._PermissionsConstService.Global.DashboardPage.View]
      },
      {
        key: "cases",
        displayName: this.uiDisplayTagService.displayTags.caseManagerNav,
        friendlyName: "cases",
        routerLink: "/cases",
        enabled: true,
        valid: true,
        active: false,
        order: 2,
        permissions: [this._PermissionsConstService.Global.CaseSearchPage.View]
      },
      {
        key: "vcompanion",
        displayName: this.uiDisplayTagService.displayTags.vCompanionNav,
        friendlyName: "vcompanion",
        routerLink: "/vcompanion",
        enabled: true,
        valid: true,
        active: true,
        order: 3,
        permissions: [this._PermissionsConstService.Global.vCompanion.View]
      },
      {
        key: "vcare",
        displayName: this.uiDisplayTagService.displayTags.vCareNav,
        friendlyName: "vcare",
        routerLink: "/vcare",
        enabled: true,
        valid: true,
        active: true,
        order: 3,
        permissions: [this._PermissionsConstService.Global.VCare.View]
      },
      {
        key: "devices",
        displayName: this.uiDisplayTagService.displayTags.directConnectNav,
        friendlyName: "devices",
        routerLink: "/devices",
        enabled: true,
        valid: true,
        active: false,
        order: 4,
        permissions: [this._PermissionsConstService.Global.DeviceManagerPage.View]
      },
      {
        key: "patients",
        displayName: this.uiDisplayTagService.displayTags.patientManagerNav,
        friendlyName: "patients",
        routerLink: "/patients",
        enabled: true,
        valid: true,
        active: false,
        order: 3,
        permissions: [this._PermissionsConstService.Global.PatientManagerPage.View]
      },
      {
        key: "queue",
        displayName: this.uiDisplayTagService.displayTags.queueManagerNav,
        friendlyName: "queue",
        routerLink: "/queue",
        enabled: true,
        valid: true,
        active: false,
        order: 2,
        permissions: [this._PermissionsConstService.Global.CaseQueuePage.View]
      },
      {
        key: "administration",
        displayName: this.uiDisplayTagService.displayTags.administrationNav,
        friendlyName: "administration",
        routerLink: "/administration",
        extraRouterLinks: ["/administration"],
        enabled: true,
        valid: true,
        active: false,
        order: 4,
        permissions: [this._PermissionsConstService.Global.AdministrationPage.View]
      },
      {
        key: "about",
        displayName: this.uiDisplayTagService.displayTags.aboutNav,
        friendlyName: "about",
        routerLink: "/about",
        extraRouterLinks: ["/dashboard"],
        enabled: true,
        valid: true,
        active: false,
        order: 5,
        permissions: [this._PermissionsConstService.Global.AboutPage.View]
      },
      {
        key: "system-information",
        displayName: this.uiDisplayTagService.displayTags.systemInformation,
        friendlyName: "system-information",
        routerLink: "/system-information",
        extraRouterLinks: ["/system-information"],
        enabled: true,
        valid: true,
        active: false,
        order: 6,
        permissions: [this._PermissionsConstService.Global.SystemInformationPage.View]
      }
    ];
    const features = this.store.selectSnapshot(OrganizationState.features);
    if (features.find(x => x.friendlyId === "contentManager")?.enabled) {
      navItems.push({
        key: "content",
        displayName: this.uiDisplayTagService.displayTags.contentManagementNav,
        friendlyName: "content",
        routerLink: "/content",
        extraRouterLinks: ["/content"],
        enabled: true,
        valid: true,
        active: false,
        order: 2,
        permissions: [this._PermissionsConstService.Global.ContentManagementPage.View]
      });
    }
    return of(navItems);
  }
}
