import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from "@angular/core";
import { Store } from "@ngxs/store";
import { CaseState } from "@vp/data-access/case";
import { getDataValueFromDotPath } from "@vp/shared/utilities";
import { BehaviorSubject, Subject } from "rxjs";
import { SchemaFieldType } from "../directives/schema-field-type.directive";

enum TogglerAction {
  ShowWhenOn = "showWhenOn",
  ClearWhenOff = "clearWhenOff"
}

export interface TogglerTrigger {
  togglerAction: string;
  field: string;
}

@Component({
  selector: "vp-formly-display-toggler-based-on-tag",
  templateUrl: "./formly-display-toggler-based-on-tag.component.html",
  styleUrl: "./formly-display-toggler-based-on-tag.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormlyDisplayTogglerBasedOnTagComponent
  extends SchemaFieldType
  implements OnDestroy, OnInit
{
  private readonly _destroyed$ = new Subject<void>();
  private readonly _selectedValue$ = new BehaviorSubject<boolean>(false);
  private readonly _showToggler$ = new BehaviorSubject<boolean>(false);

  selectedValue$ = this._selectedValue$.asObservable();
  showToggler$ = this._showToggler$.asObservable();

  constructor(private readonly store: Store) {
    super();
  }

  ngOnInit(): void {
    const caseData = this.store.selectSnapshot(CaseState.current);
    const showToggler = caseData?.tags.some(x => this.props.tags.includes(x)) ?? false;
    this._showToggler$.next(showToggler);

    if (caseData?.recordData) {
      const currentField = getDataValueFromDotPath(
        caseData?.recordData,
        `${this.props.parentName}.${this.props.fieldName}`
      ) as boolean;
      if (currentField) {
        this._selectedValue$.next(currentField);
        this.setFieldValue(this.props.fieldName, currentField);

        const currentDateField = getDataValueFromDotPath(
          caseData?.recordData,
          `${this.props.parentName}.${this.props.dateFieldName}`
        ) as string;
        if (currentDateField) {
          this.setFieldValue(this.props.dateFieldName, currentDateField);
        }
      }
    }
  }

  setFieldValue(fieldName: string, value: any) {
    const fieldGroup = this.field.parent?.fieldGroup?.find(f => f.key === fieldName);
    if (fieldGroup) {
      fieldGroup.formControl?.setValue(value);
    }
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  onToggle(selectedValue: any) {
    const newValue = selectedValue.checked;
    this._selectedValue$.next(newValue);
    const listOfActions = this.props.triggers as TogglerTrigger[];
    listOfActions.map(x => {
      this.triggerController(x.field, x.togglerAction, newValue);
    });
    const fieldGroup = this.field.parent?.fieldGroup?.find(f => f.key === this.props.fieldName);
    fieldGroup?.formControl?.setValue(newValue);
  }

  triggerController(fieldName: string, togglerAction: string, selectedValue: boolean) {
    const fieldGroup = this.field.parent?.fieldGroup?.find(f => f.key === fieldName);
    if (fieldGroup) {
      switch (togglerAction) {
        case TogglerAction.ShowWhenOn:
          fieldGroup.hide = selectedValue ? false : true;
          break;
        case TogglerAction.ClearWhenOff:
          if (!selectedValue) fieldGroup.formControl?.setValue("");
          break;
        default:
          break;
      }
    }
  }
}
