import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { FieldType, FieldTypeConfig } from "@ngx-formly/core";
import { Select } from "@ngxs/store";
import { CaseTypesState } from "@vp/data-access/case-types";
import { UserState } from "@vp/data-access/users";
import { CaseType, UserRole } from "@vp/models";
import { filterNullMap } from "@vp/shared/operators";
import { BehaviorSubject, Observable, tap, withLatestFrom } from "rxjs";

@Component({
  selector: "lib-formly-create-account-type",
  templateUrl: "./formly-create-account-type.component.html",
  styleUrl: "./formly-create-account-type.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormlyCreateAccountTypeComponent extends FieldType<FieldTypeConfig> implements OnInit {
  @Select(CaseTypesState.currentCaseType) private caseType$!: Observable<CaseType>;
  @Select(UserState.currentUserRole) currentUserRole$!: Observable<UserRole>;

  showCheckbox$ = new BehaviorSubject<boolean>(false);

  constructor() {
    super();
  }
  ngOnInit(): void {
    this.caseType$
      .pipe(
        filterNullMap(),
        withLatestFrom(this.currentUserRole$.pipe(filterNullMap())),
        tap(([caseType, userRole]: [CaseType, UserRole]) => {
          if (
            this.props.deptFriendlyId &&
            caseType.departments.some(d => d.friendlyId === this.props.deptFriendlyId)
          ) {
            if (caseType.creation.createLoginRoles.some(r => r === userRole.roleId)) {
              this.showCheckbox$.next(true);
            }
          }
        })
      )
      .subscribe();
  }
}
