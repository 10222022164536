<ng-container
  [ngTemplateOutlet]="template"
  [ngTemplateOutletContext]="{
    selectedValue: (selectedValue$ | async),
    rolesAllowedToUseOnBehalfFeature: (rolesAllowedToUseOnBehalfFeature$ | async)
  }"
>
</ng-container>

<ng-template
  #template
  let-selectedValue="selectedValue"
  let-rolesAllowedToUseOnBehalfFeature="rolesAllowedToUseOnBehalfFeature"
>
  <mat-slide-toggle
    *ngIf="props.module == 'wizard' && rolesAllowedToUseOnBehalfFeature"
    [checked]="selectedValue"
    [matTooltip]="selectedValue ? 'Enabled' : 'Disabled'"
    (change)="onToggle($event)"
  >
    {{ props.description }}
  </mat-slide-toggle>
</ng-template>
