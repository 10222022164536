<div>
  <textarea
    title="Text Area"
    matInput
    [id]="id"
    [readonly]="props.readonly"
    [required]="props.required || false"
    [formControl]="formControl"
    [cols]="props.cols"
    [rows]="props.rows"
    [formlyAttributes]="field"
    [placeholder]="props.placeholder ?? ''"
    [tabindex]="props.tabindex"
    [cdkTextareaAutosize]="props.autosize"
    [cdkAutosizeMinRows]="props.autosizeMinRows"
    [cdkAutosizeMaxRows]="props.autosizeMaxRows"
    [class.cdk-textarea-autosize]="props.autosize"
    [errorStateMatcher]="textAreaErrorMatcher"
    [attr.maxlength]="props.maxLength"
    (keydown.enter)="preventEnter($event)"
    (paste)="onPaste($event)"
    #text
  ></textarea>
  @if (props.maxLength) {
    <mat-hint flexLayout="row" flexLayoutAlign="end"
      >{{ text.value.length }} / {{ props.maxLength }}</mat-hint
    >
  }

  @if (props.hintEnable) {
    <mat-hint class="hintText">Increase the TextBox size here ↑</mat-hint>
  }
</div>
