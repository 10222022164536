import {
  NgxMatDatetimePickerModule,
  NgxMatNativeDateModule,
  NgxMatTimepickerModule
} from "@angular-material-components/datetime-picker";
import { CommonModule } from "@angular/common";
import {
  Inject,
  InjectionToken,
  ModuleWithProviders,
  NgModule,
  Optional,
  Provider
} from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatChipsModule } from "@angular/material/chips";
import { MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatNativeDateModule } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatDialogModule } from "@angular/material/dialog";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatListModule } from "@angular/material/list";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatTableModule } from "@angular/material/table";
import { MatTabsModule } from "@angular/material/tabs";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatTooltipModule } from "@angular/material/tooltip";
import { AngularEditorModule } from "@kolkov/angular-editor";
import { MomentDatetimeAdapter } from "@ng-matero/extensions-moment-adapter";
import {
  DatetimeAdapter,
  MTX_DATETIME_FORMATS,
  MtxNativeDatetimeModule
} from "@ng-matero/extensions/core";
import { MtxDatetimepickerModule } from "@ng-matero/extensions/datetimepicker";
import { FormlyModule } from "@ngx-formly/core";
import { FormlySelectModule } from "@ngx-formly/core/select";
import { FormlyMaterialModule } from "@ngx-formly/material";
import { FormlyFieldCheckbox } from "@ngx-formly/material/checkbox";
import { FormlyMatDatepickerModule } from "@ngx-formly/material/datepicker";
import { FormlyMatToggleModule } from "@ngx-formly/material/toggle";
import { TagsDataAccessModule } from "@vp/data-access/tags";
import {
  UI_SCHEMA_CONFIG,
  UiSchemaCollection,
  UiSchemaConfigService,
  UiSchemaLayoutProvider
} from "@vp/formly/ui-schema-config";
import { PhoneInputModule } from "@vp/shared/components/phone-input";
import { FlexModule } from "@vp/shared/directives/flex";
import { FocusStateModule } from "@vp/shared/directives/focus-state";
import { SpecialtyTagsComponent } from "@vp/user-profile/ui/specialty-tags";
import { InsertSnippetModule } from "@vp/user-snippets/ui/insert-snippet";
import { JSONSchema7 } from "json-schema";
import { MatTimepickerModule } from "mat-timepicker";
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from "ngx-mask";
import { CustomMultiSelectComponent } from "./custom-multi-select/custom-multi-select.component";
import { DateTimePickerComponent } from "./date-time-picker/date-time-picker.component";
import { DescriptionWrapperComponent } from "./description-wrapper/description-wrapper.component";
import { ExpansionPanelWrapperComponent } from "./expansion-panel-wrapper/expansion-panel-wrapper.component";
import { FormInputComponent } from "./form-input/form-input.component";
import { FormlyArrayTypeComponent } from "./formly-array-type/formly-array-type.component";
import { FormlyConditionPickerComponent } from "./formly-condition-picker/formly-condition-picker.component";
import { FormlyConditionTypeSelectorComponent } from "./formly-condtion-type-selector/formly-condition-type-selector.component";
import { FormlyCreateAccountTypeComponent } from "./formly-create-account-type/formly-create-account-type.component";
import { FormlyDisplayTogglerBasedOnTagComponent } from "./formly-display-toggler-based-on-tag/formly-display-toggler-based-on-tag.component";
import { FormlyDobPickerComponent } from "./formly-dob-picker/formly-dob-picker.component";
import { FormlyFlexInputWrapperComponent } from "./formly-flex-input-wrapper/formly-flex-input-wrapper.component";
import { FormlyFlexLayoutTypeComponent } from "./formly-flex-layout-type/formly-flex-layout-type.component";
import { FormlyFlexLayoutWrapperComponent } from "./formly-flex-layout-wrapper/formly-flex-layout-wrapper.component";
import { FormlyHiddenTypeComponent } from "./formly-hidden-type/formly-hidden-type.component";
import { FormlyIntlPhoneInputTypeComponent } from "./formly-intl-phone-input-type/formly-intl-phone-input-type.component";
import { FormlyMaskedTypeComponent } from "./formly-masked-type/formly-masked-type.component";
import { FormlyMixedSchemaTypeComponent } from "./formly-mixed-schema-type/formly-mixed-schema-type.component";
import { FormlyMultiSchemaTypeComponent } from "./formly-multi-schema-type/formly-multi-schema-type.component";
import { FormlyNullTypeComponent } from "./formly-null-type/formly-null-type.component";
import { FormlyObjectTypeComponent } from "./formly-object-type/formly-object-type.component";
import { FormlyOnBehalfTogglerComponent } from "./formly-onbehalf-toggler/formly-onbehalf-toggler.component";
import { FormlyReadonlyFieldComponent } from "./formly-readonly-field/formly-readonly-field.component";
import { DisableAngularEditorDirective } from "./formly-rich-text-type/disable-angular-editor.directive";
import { FormlyRichTextTypeComponent } from "./formly-rich-text-type/formly-rich-text-type.component";
import { FormlyTableLayoutTypeComponent } from "./formly-table-layout-type/formly-table-layout-type.component";
import { FormlyTabsLayoutTypeComponent } from "./formly-tabs-layout-type/formly-tabs-layout-type.component";
import { FormlyTextareaTypeComponent } from "./formly-textarea-type/formly-textarea-type.component";
import { FilterUsersByTagsAndRoleComponent } from "./formly-users-by-tags-and-role/formly-users-by-tags-and-role.component";
import {
  constValidationMessage,
  exclusiveMaximumValidationMessage,
  exclusiveMinimumValidationMessage,
  maxItemsValidationMessage,
  maxValidationMessage,
  maxlengthValidationMessage,
  minItemsValidationMessage,
  minValidationMessage,
  minlengthValidationMessage,
  multipleOfValidationMessage
} from "./formly-validation-message.config";
import { JsonInputAccessorDirective } from "./json-edit-type/json-accessor.directive";
import { JsonEditTypeComponent } from "./json-edit-type/json-edit-type.component";
import { ModalWrapperComponent } from "./modal-wrapper/modal-wrapper.component";
import { MultiSelectAutoCompleteComponent } from "./multi-select-auto-complete/multi-select-auto-complete.component";
import { StringArrayEditTypeComponent } from "./string-array-edit-type/string-array-edit-type.component";
import { TimePickerTypeComponent } from "./time-picker-type/time-picker-type.component";
import { MemoizePipe } from "./utilities/memoize.pipe";

export const DATE_FORMATS = {
  parse: {
    dateInput: "MM/DD/YYYY"
  },
  display: {
    dateInput: "MM/DD/YYYY",
    monthYearLabel: "MMMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY"
  }
};

export abstract class JsonSchemaValueProvider {
  abstract provide(schema: JSONSchema7, state: Record<string, unknown>): void;
}

export const JSON_SCHEMA_VALUE_PROVIDER = new InjectionToken<JsonSchemaValueProvider[]>(
  "JSON_SCHEMA_VALUE_PROVIDER"
);

export interface JsonSchemaConfigOptions {
  providers?: JsonSchemaValueProvider[];
  uischema: UiSchemaCollection;
}

@NgModule({
  declarations: [
    DisableAngularEditorDirective,
    ExpansionPanelWrapperComponent,
    FormInputComponent,
    FormlyArrayTypeComponent,
    FilterUsersByTagsAndRoleComponent,
    FormlyFlexInputWrapperComponent,
    FormlyFlexLayoutTypeComponent,
    FormlyFlexLayoutWrapperComponent,
    FormlyIntlPhoneInputTypeComponent,
    FormlyMaskedTypeComponent,
    FormlyMixedSchemaTypeComponent,
    FormlyNullTypeComponent,
    FormlyObjectTypeComponent,
    FormlyRichTextTypeComponent,
    FormlyTableLayoutTypeComponent,
    FormlyTabsLayoutTypeComponent,
    FormlyTextareaTypeComponent,
    FormlyHiddenTypeComponent,
    FormlyReadonlyFieldComponent,
    JsonEditTypeComponent,
    JsonInputAccessorDirective,
    MemoizePipe,
    ModalWrapperComponent,
    StringArrayEditTypeComponent,
    TimePickerTypeComponent,
    FormlyDobPickerComponent,
    FormlyConditionTypeSelectorComponent,
    FormlyCreateAccountTypeComponent,
    FormlyConditionPickerComponent,
    DateTimePickerComponent,
    DescriptionWrapperComponent,
    CustomMultiSelectComponent,
    MultiSelectAutoCompleteComponent,
    FormlyOnBehalfTogglerComponent,
    FormlyDisplayTogglerBasedOnTagComponent
  ],
  exports: [DisableAngularEditorDirective, FormlyMixedSchemaTypeComponent],
  imports: [
    FormlySelectModule,
    FocusStateModule,
    AngularEditorModule,
    CommonModule,
    FlexModule,
    FormlyMatDatepickerModule,
    FormlyMaterialModule,
    FormsModule,
    InsertSnippetModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatCardModule,
    MatCardModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatNativeDateModule,
    MatRadioModule,
    MatSelectModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTooltipModule,
    MatSlideToggleModule,
    PhoneInputModule,
    TagsDataAccessModule,
    MatTimepickerModule,
    MtxDatetimepickerModule,
    MtxNativeDatetimeModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    NgxMatNativeDateModule,
    NgxMaskDirective,
    NgxMaskPipe,
    MatCheckboxModule,
    FormlyModule.forRoot({
      extras: {
        lazyRender: true,
        resetFieldOnHide: false
      },
      validationMessages: [
        { name: "required", message: "This field is required" },
        { name: "null", message: "should be null" },
        { name: "whitespace", message: "This field cannot be left blank" },
        { name: "minLength", message: minlengthValidationMessage },
        { name: "maxLength", message: maxlengthValidationMessage },
        { name: "min", message: minValidationMessage },
        { name: "max", message: maxValidationMessage },
        { name: "multipleOf", message: multipleOfValidationMessage },
        { name: "pattern", message: "Invalid input/character" },
        {
          name: "exclusiveMinimum",
          message: exclusiveMinimumValidationMessage
        },
        {
          name: "exclusiveMaximum",
          message: exclusiveMaximumValidationMessage
        },
        { name: "minItems", message: minItemsValidationMessage },
        { name: "maxItems", message: maxItemsValidationMessage },
        { name: "uniqueItems", message: "should not have duplicate items" },
        { name: "const", message: constValidationMessage }
      ],
      types: [
        {
          name: "flex-layout",
          component: FormlyFlexLayoutTypeComponent
        },
        {
          name: "object",
          component: FormlyFlexLayoutTypeComponent
        },
        {
          name: "input",
          defaultOptions: { modelOptions: { updateOn: "change" } },
          component: FormInputComponent
        },
        {
          name: "checkbox",
          component: FormlyFieldCheckbox,
          wrappers: ["flex-input"]
        },
        { name: "string", extends: "input", wrappers: ["form-field"] },
        { name: "boolean", extends: "checkbox" },
        { name: "datepicker", wrappers: ["form-field"] },
        { name: "enum", extends: "select" },
        { name: "multischema", component: FormlyMultiSchemaTypeComponent },
        { name: "rich-text", component: FormlyRichTextTypeComponent },
        {
          name: "users-by-tag-and-role",
          component: FilterUsersByTagsAndRoleComponent
        },
        { name: "onbehalf-toggler", component: FormlyOnBehalfTogglerComponent },
        { name: "toggler-based-on-tag", component: FormlyDisplayTogglerBasedOnTagComponent },
        { name: "array", component: FormlyArrayTypeComponent },
        { name: "tabs", component: FormlyTabsLayoutTypeComponent },
        { name: "table", component: FormlyTableLayoutTypeComponent },
        {
          name: "mixed-schema",
          component: FormlyMixedSchemaTypeComponent
        },
        {
          name: "masked",
          wrappers: ["form-field"],
          extends: "input",
          component: FormlyMaskedTypeComponent
        },
        {
          name: "number",
          extends: "input",
          wrappers: ["form-field"],
          defaultOptions: {
            templateOptions: {
              type: "number"
            }
          }
        },
        {
          name: "integer",
          extends: "input",
          wrappers: ["form-field"],
          defaultOptions: {
            templateOptions: {
              type: "number"
            }
          }
        },
        {
          name: "null",
          component: FormlyNullTypeComponent,
          wrappers: ["form-field"]
        },
        {
          name: "grid",
          component: FormlyFlexLayoutTypeComponent,
          defaultOptions: {
            templateOptions: {
              columns: 2
            }
          }
        },
        {
          name: "textarea",
          component: FormlyTextareaTypeComponent,
          defaultOptions: {
            templateOptions: {
              rows: 4,
              cols: 100,
              autosizeMinRows: 3,
              autosizeMaxRows: 5,
              autosize: true
            }
          }
        },
        {
          name: "intl-phone",
          component: FormlyIntlPhoneInputTypeComponent,
          wrappers: ["form-field"]
        },
        {
          name: "time-picker",
          component: TimePickerTypeComponent
        },
        {
          name: "formly-dob-picker",
          component: FormlyDobPickerComponent
        },
        {
          name: "formly-condition-picker",
          component: FormlyConditionPickerComponent
        },
        {
          name: "date-time-picker",
          component: DateTimePickerComponent
        },
        {
          name: "formly-condition-type-selector",
          component: FormlyConditionTypeSelectorComponent
        },
        {
          name: "formly-create-account-type",
          component: FormlyCreateAccountTypeComponent
        },
        { name: "hidden-type", component: FormlyHiddenTypeComponent },
        { name: "readonly-field", component: FormlyReadonlyFieldComponent },
        {
          name: "custom-multi-select",
          component: CustomMultiSelectComponent
          // wrappers: ["form-field"]
        },
        {
          name: "multi-select-auto-complete",
          component: MultiSelectAutoCompleteComponent
        },
        {
          name: "specialty-tags",
          component: SpecialtyTagsComponent
        }
      ],
      wrappers: [
        {
          name: "description-wrapper",
          component: DescriptionWrapperComponent
        },
        {
          name: "modal-wrapper",
          component: ModalWrapperComponent
        },
        {
          name: "flex-wrapper",
          component: FormlyFlexLayoutWrapperComponent
        },
        {
          name: "flex-input",
          component: FormlyFlexInputWrapperComponent
        },
        {
          name: "expansion-panel",
          component: ExpansionPanelWrapperComponent
        }
      ]
    }),
    FormlyMatToggleModule
  ],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: "en-US" },
    //{ provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: DATE_FORMATS },
    { provide: DatetimeAdapter, useClass: MomentDatetimeAdapter },
    {
      provide: MTX_DATETIME_FORMATS,
      useValue: {
        parse: {
          dateInput: "YYYY-MM-DD",
          monthInput: "MMMM",
          timeInput: "HH:mm",
          datetimeInput: "YYYY-MM-DD HH:mm"
        },
        display: {
          dateInput: "YYYY-MM-DD",
          monthInput: "MMMM",
          timeInput: "HH:mm",
          datetimeInput: "YYYY-MM-DD HH:mm",
          monthYearLabel: "YYYY MMMM",
          dateA11yLabel: "LL",
          monthYearA11yLabel: "MMMM YYYY",
          popupHeaderDateLabel: "MMM DD, ddd"
        }
      }
    },
    provideNgxMask()
  ]
})
export class FormlyJsonSchemaModule {
  constructor(
    configService: UiSchemaConfigService,
    @Optional() @Inject(UI_SCHEMA_CONFIG) config: UiSchemaCollection[] = []
  ) {
    if (!config) {
      return;
    }
    config.forEach(config => configService.addConfig(config));
  }

  static forRoot(options: JsonSchemaConfigOptions): ModuleWithProviders<FormlyJsonSchemaModule> {
    return {
      ngModule: FormlyJsonSchemaModule,
      providers: [...registerProviders(options), UiSchemaConfigService, UiSchemaLayoutProvider]
    };
  }

  static forChild(options: JsonSchemaConfigOptions): ModuleWithProviders<FormlyJsonSchemaModule> {
    return {
      ngModule: FormlyJsonSchemaModule,
      providers: [...registerProviders(options), UiSchemaLayoutProvider]
    };
  }
}

const registerProviders = (options: JsonSchemaConfigOptions) => {
  const providers: Provider[] = [
    {
      provide: UI_SCHEMA_CONFIG,
      multi: true,
      useFactory: defaultConfig,
      deps: [UiSchemaConfigService]
    },
    { provide: UI_SCHEMA_CONFIG, multi: true, useValue: options.uischema }
  ];

  if (options.providers && options.providers.length > 0) {
    options.providers.forEach(provider => {
      providers.push({
        provide: JSON_SCHEMA_VALUE_PROVIDER,
        multi: true,
        useValue: provider
      });
    });
  }

  return providers;
};

const defaultConfig = (): UiSchemaCollection => {
  return {
    layouts: [],
    definitions: []
  } as UiSchemaCollection;
};
