<ng-container
  [ngTemplateOutlet]="template"
  [ngTemplateOutletContext]="{
    usersToDisplay: (usersToDisplay$ | async),
    selectedUser: (selectedUser$ | async),
    rolesAllowedToUseOnBehalfFeature: (rolesAllowedToUseOnBehalfFeature$ | async)
  }"
>
</ng-container>
<ng-template
  #template
  let-usersToDisplay="usersToDisplay"
  let-selectedUser="selectedUser"
  let-rolesAllowedToUseOnBehalfFeature="rolesAllowedToUseOnBehalfFeature"
>
  @if (props.module == "wizard" && rolesAllowedToUseOnBehalfFeature) {
    <mat-form-field>
      <mat-label>{{ props.label }}</mat-label>
      <mat-select [value]="selectedUser" [formlyAttributes]="field">
        <mat-option [value]="" (click)="toggleSelection(null)">{{ props.defaultValue }}</mat-option>
        @for (user of usersToDisplay; track user.userId) {
          <mat-option [value]="user.userId" (click)="toggleSelection(user)">{{
            displayedName(user)
          }}</mat-option>
        }
      </mat-select>
    </mat-form-field>
  }
</ng-template>
