<ng-container
  [ngTemplateOutlet]="template"
  [ngTemplateOutletContext]="{
    selectedValue: (selectedValue$ | async),
    showToggler: (showToggler$ | async)
  }"
>
</ng-container>

<ng-template #template let-selectedValue="selectedValue" let-showToggler="showToggler">
  @if (showToggler) {
    <mat-slide-toggle
      [disabled]="props.disabled"
      [checked]="selectedValue"
      [matTooltip]="selectedValue ? 'Enabled' : 'Disabled'"
      (change)="onToggle($event)"
    >
      {{ props.description }}
    </mat-slide-toggle>
  }
</ng-template>
